import GATSBY_COMPILED_MDX from "/opt/build/repo/src/articles/2022/mac-slippi-streaming/index.mdx";
import React from 'react';
import styled from 'styled-components';
import {MDXProvider} from '@mdx-js/react';
import {Link, graphql} from 'gatsby';
import {typography} from '../../styles/typography';
import Container from '../Container';
import config from '../../config';
import Seo from '../Seo';
import {createArticleDate, useHasMounted} from '../../helpers';
const StyledArticleDate = (styled.span)`
  ${typography.span};
`;
const StyledArticleHeading = (styled.h1)`
  ${typography.h3};
  letter-spacing: 1px;
  text-transform: none;
`;
const StyledArticleSubheading = (styled.p)`
  ${typography.p};
  color: ${config.colors.alt};
  font-style: italic;
  font-size: 0.9em;
`;
const StyledBody = (styled.div)`
  ${typography.p};

  h3 {
    ${typography.h3};
  }
  h4 {
    ${typography.h4};
  }
  h5 {
    ${typography.h5};
  }
  h6 {
    ${typography.h6};
  }

  p {
    ${typography.p};
  }

  ul {
  }
`;
const shortcodes = {
  Link
};
function ArticleLayout({data, children}) {
  const hasMounted = useHasMounted();
  const {title, subheading, date} = data.mdx.frontmatter;
  const formattedArticleDate = createArticleDate(date);
  if (!hasMounted) {
    return null;
  }
  return React.createElement(React.Fragment, null, React.createElement(Seo, {
    title: title,
    description: subheading
  }), React.createElement(Container, {
    verticalPadding: false
  }, React.createElement(StyledArticleDate, null, formattedArticleDate), React.createElement(StyledArticleHeading, null, title), React.createElement(StyledArticleSubheading, null, subheading), React.createElement(StyledBody, null, React.createElement(MDXProvider, {
    components: shortcodes
  }, children))));
}
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        subheading
        date
      }
    }
  }
`;
ArticleLayout
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ArticleLayout, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
